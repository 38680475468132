
/* <====================Main center start================> */
.main-center{
    margin-top: 110px;
    /* background: blueviolet; */
  }
  .main-center-container{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0;
  
  }
  .main-center-container-child{
    display: flex;
     
      align-items: center;
      flex-direction: column;
      gap: 16px;
      margin-top: 0px;
      width: 100%;
      /* background: rgba(97, 97, 137, 0.675); */
  }
  
  .main-center-container-child-card{
    padding: 40px;
    gap: 40px;
    width: 648px;

  
  display: flex;
  border-radius: 24px;
  background: var(--chakra-colors-backgroundCard);
  padding: 0px 16px 24px;
  flex-direction: column;
  /* background: rgb(47, 46, 46); */

  }
  .right-side{
    position: fixed;
    right:2.5rem;
    top: 140px;
    padding-top: 0px;
    max-width: 280px;
   
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
  }
  .right-side-one{
    /* background: rgb(56, 57, 57); */
    background: #262525;
      padding: 1.5rem;
      font-size: 12px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      
  }
  .right-side-one-first{
    font-size: 20px;
    font-weight: 800;
    margin: 0;
   
    padding: 0;
  
  }
  .right-side-one-second{
    display: flex;
    flex-direction: column;
    gap: 16px;
  
  }
  .img-rght-side{
    width:32px;
    height: 30px;
    border-radius: 50%;
    margin-top: 3px;
  }
  .right-side-one-second-row{
    display: flex;
    gap: 5px;
  }
  .right-side-one-second-row-name{
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-size: 14px;
    
  }
  .right-side-two{
    /* background: rgb(56, 57, 57); */
    background:#262525;
    padding: 1.5rem;
    font-size: 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  
  }
  
  .right-side-two-first{
    font-size: 20px;
    font-weight: 800;
  
  }
  .right-side-two-sec{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .right-side-two-sec-one{
    display: flex;
    justify-content: space-between;
  
  }
  .right-side-two-sec-row{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .right-side-two-sec-row-fisrt{
    display: flex;
    gap: 5px;
    font-size: 15px;
  }
  .img-rght-side-sec{
    width: 23px;
    height: 22px;
    border-radius: 50%;
  }
  
  /* ++++++++++++++++start1 */
  .fisrt-stake-container {
    background: #262525;
  
    margin-bottom: 2rem;
    display: flex;
   
    flex-direction: column;
    gap: 6px;
    /* width: 37rem; */
    min-height: 16rem;
    margin-bottom: 2rem;
    border-radius: 9px;
    justify-content: space-between;
    padding: 20px;
  }
  .fisrt-stake-container-heading {
    align-items: center;
    text-align: center;
  }
  .fisrt-stake-container-sec {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 7px;
  }
  .restake-btn {
    border: none;
    border-radius: 19px;
    background: #06f7f7;
    color: black;
    font-weight: 700;
    width: 50%;
    height: 2.5rem;
    font-size: 16px;
  }
  
  /* ++++++++++++++++end1 */
  
  .second-main-container{
    display: flex;
    flex-direction: column;
    background: #262525;
  
    margin-bottom: 2rem;
    gap: 6px;
   /* width: 37rem; */
    min-height: 13rem;
    margin-bottom: 2rem;
    border-radius: 9px;
    /* justify-content: space-between; */
    padding: 20px;
  }
  .second-main-container-child-one{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .second-main-container-child-one-first{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .second-main-container-child-one-first-border{
    display: flex;
    gap: 3px;
    border: 1px solid rgba(25, 107, 25, 0.839);
    border-radius: 8px;
    height: 30px;
    padding: 3px 5px 3px 5px;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
  .second-main-container-child-one-second{
    display: flex;
    justify-content: space-between;
  
  }
  .second-main-container-child-one-third{
    display: flex;
    justify-content: space-between;
  }
  .second-main-container-child-one-four{
    display: flex;
    justify-content: center;
   
  }
  .second-main-container-child-one-four-child{
    height: 4rem;
    width: 55%;
    border-radius: 16px;
    background: rgb(31, 30, 30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
  

  }
  .second-main-container-child-one-four-child-top{
    padding: 6px 13px;
    border-radius: 16px;
    color: white;
    cursor: pointer;
  }
  
  .second-main-container-child-one-four-child-active{
    background: #0bc7c7;
 
  transition: all 100ms linear;
    color: black;
    /* padding: 6px 20px 6px 20px; */
    
    font-size: 18px;
    text-align: center;
    font-weight: 600;
  }
  .second-main-container-child-one-five{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 8rem;
    background: #212121;
    border-radius: 1rem;
    padding: 8px;
  }
  .second-main-container-child-one-five-first{
    color: #696868;
    display: flex;
    justify-content: flex-start;
  }
  .second-main-container-child-one-five-second{
  display: flex;
  justify-content: space-between;
  
  }
  .second-main-container-child-one-five-second-input{
    background: transparent;
    border: none;
    outline: none;
    height: 3rem;
    /* background: #262525; */
    width: 70%;
    font-size: 32px;
    border-right: 1px solid rgb(50, 49, 49);
    padding: 8px 16px;
  }
 
  .second-main-container-child-one-five-second-dropdown{
   display: flex;
   justify-content: flex-end;
   width: 30%;
   padding-right: 16px;
   align-items: center;
  
  }
  .second-main-container-child-one-five-second-dropdown-drp{
    background: black;
    border-radius: 16px;
    color: white;
    padding: 4px 8px 4px 8px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content:space-between;
    gap: 6px;
    width: 5.5rem;
  }
  .imgLogo{
    width: 22px;
    height: 18px;
    border-radius: 50%;

  }
  .second-main-container-child-one-five-second-dropdown-drp-content{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 27px;
    /* margin-top: 5px; */
    left: 0;
    background: black;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    transition: ease 0.3s linear;

  }
  .second-main-container-child-one-five-second-dropdown-drp-content-item{
 
    padding: 8px;
    border-top: 1px solid rgb(48, 48, 48);
    width: 100%;
   cursor: pointer;
    color: white;
  }
  .second-main-container-child-one-five-second-dropdown-drp-content-item:nth-child(1){
    border-top: none;

  }
  .second-main-container-child-one-five-third{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;

  }
  .second-main-container-child-one-six{
    display: flex;
    justify-content: center;
    
  }
  .balance{
    font-size: 16px;
    color: #4e4e4e;
    font-weight: bold;
  }
  .max-btn{
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 2px;
    cursor: pointer;
  }
  .second-main-container-child-one-six span{
    font-size: 30px;
  }
  .second-main-container-child-one-seven{
    background: #06f7f7;
    color: black;
    padding: 4px 8px 4px 8px;
    border-radius: 16px;
    text-align: center;
  }
  .second-main-container-child-one-eight{
    background: #151817;
    height: 3rem;
    color: white;
    cursor: pointer;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .swap-btn{
    cursor: pointer;
    width: 23px;
    height: 23px;
    /* ========== */
    background: #0a0a0a;
    padding: 3px;
    border-radius: 50%;
  }
  .swap-btn:hover{
    background: #1d1c1c;
  }

  .swap-btn .active{
    rotate: deg(180deg);
    background: #1d1c1c;
  }

  /* ================================for the info popup start */
  
.icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icon {
  font-size: 15px;
  cursor: pointer;
  margin-top: 2px;

}

.popup {
  display: none;
  position: absolute;
  top: 18px; /* Adjust based on your icon size */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  color: white;
  padding: 13px;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 1;
  /* for the many input */

}
.popupmain{
  display: none;
  position: absolute;
  top: 18px; /* Adjust based on your icon size */
  left: 50%;
  transform: translateX(-20%);
  
  background-color: #000000;
  color: white;
  padding: 13px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 1;
}
.popup2 {
  display: none;
  position: absolute;
  top: 18px; /* Adjust based on your icon size */
  left: 50%;
  transform: translateX(-100%);
  background-color: #000000;
  color: white;
  padding: 13px;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 1;
  /* for the many input */

}
.popup-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.popup-content1{
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.text-col{
  color: #06f7f7;
  font-size: 13px;
  font-weight: 700;
}
.icon-container:hover .popup {
  display: block;
}
.icon-container:hover .popup1 {
  display: block;
}

.icon-container:hover .popupmain {
  display: block;

}

  /* ================================for the info popup end */

  @media screen and (max-width: 1250px) {
    .right-side{
      max-width: 648px;
      position: initial;
      margin: auto;
      padding: 0px 16px 24px;
     
    }
    .right-side-one-second-row-name{
      flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        align-items: center;
     
    }
  }
  @media screen and (max-width: 730px) {
    .main-center-container-child-card{
      max-width: 100%;
      width: 100%;
    }
   .right-side{
    display: flex;
    flex-direction: column;
    position: initial;
    right: initial;
    top: initial;
    width: 100%;
    max-width: 100%; 
    margin: auto;
   }

  }

  @media screen and (max-width: 575px) {
    .second-main-container-child-one-four-child{
      width: 80%;
    }
    .second-main-container-child-one-five-second-input{
      width: 61%;
    }
      .second-main-container-child-one-five-second-dropdown{
        width: 39%;
      }
  }
  