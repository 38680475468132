
/* <===================Header start=================> */
.header-parent {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    padding-right: 0px;
    padding-left: 0px;
    /* padding-top: 2rem; */
    padding-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    width: calc(100% - 240px);
    background: black;
    right: 0px;
    top: 0px;
    box-sizing: border-box;
    z-index: 2;

  }
  
  .header-left{
    display: flex;
    justify-content: left;
    gap: 5px;
    color: white;
    display: none;
  
  }
  .header-right{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header-right-child{
    display: flex;
    gap:5px;
  
  }
  
  .header-right-child-one{
    position: relative;
    display: flex;
    gap: 4px;
    padding:16px 16px 16px 16px ;
    background: rgb(25, 25, 25);
    border-radius: 8px;
    
  }

  .metmaskHeader-img{
    height: 18px;
    width: 18px;
  }
  .copyBtn{
    font-size: 18px;
  }
  .darkWhite{
    width: 18px;
    height: 18px;
  }
  .header-right-child-two{
    position: relative;
    display: flex;
    gap: 4px;
    padding:16px 16px 16px 16px ;
    background: rgb(25, 25, 25);
    border-radius: 8px;
  }
  .header-right-child-two:active{
 
    transition: Btn-animation all ease-in  500ms linear ;

  }
  @keyframes Btn-animation {
    0%{ background: rgb(25, 25, 25);}
    100%{background:rgb(35, 35, 35) ;}
  }

  .three-dot{
    margin-left: 6px;
    margin-right: 2.5rem;
    display: inline-flex;
    position: relative;
  
    background: transparent;
    color: white;
    outline: none;
    border: none;
    background: rgb(18, 18, 18);
    padding: 5px 2px 5px 2px;
    border-radius:8px;
    box-sizing: border-box;
    cursor: pointer;
    
  }
  .three-dot span{
    font-size: 40px;
  }
  /* .three-dot-dropdown{
    visibility: visible;
    min-width: max-content;
    --popper-transform-origin: top left;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(976px, 96px, 0px);
  } */
  .logo{
    margin-left: 10px;
  }
  .img-logo-nav{
    width: 100px
  }
  .dropDownParent{
    visibility: visible;
    min-width: max-content;
    --popper-transform-origin: top left;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(760px, 80px, 0px);
    z-index: 4;
  }
  .dropDownParentTwo{
    outline: transparent solid 2px;
    outline-offset: 2px;
    --menu-bg: #fff;
    --menu-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: inherit;
    min-width: 14rem;
    border-radius: 1rem;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color:white;
    background: black;
    padding: 0rem 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.12) 4px 24px 40px;
    width: 280px;
    max-width: 100%;
    transform-origin:top left;
    opacity: 1;
    visibility: visible;
    transform: none;
  
  }

  /* ============================ Header dropdown*/
  
  /* ============================================ */
  
.dropdown-content{
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 100%;
  right: 2.5rem;
  background-color: #151313;
  color: white;
  box-shadow: 0px 8px 16px rgba(11, 11, 11, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 15px;
  min-width: 280px;
  min-height: 325px;
  justify-content: space-between !important;
  
}

.dropdown-content a {
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.dropdown-content a:hover {
  background-color: #252424;

}
.dropdown-symbol{
  width: 21px;
  height: 21px;
  position: relative;
  top: 3px;
  color: #fff;
}
.drop-icon_symbol{
  font-size: 21px;
  background: #000000;
  padding: 2px;
  border-radius: 50%;
  position: relative;
  top: 1px;
}

  /* ==================== */


  @media screen and (max-width: 1250px) {
    .header-left{
      display: block;
      display: flex;
      width: 20px;
      height: 20px;
      
      
    }
    .header-parent{
      width: 100%;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 730px) {
    .img-logo-nav{
      width: 60px
    }
    .three-dot {
      margin-right: 0.4rem;
    }
    .header-right-child-one {
      padding: 5px 5px 5px 5px;
      height: 2.5rem;
      align-items: center;
    }
    .header-right-child-two{
      padding: 5px 5px 5px 5px;
      height: 2.5rem;
      align-items: center;
    }
    .three-dot span {
      font-size: 30px;
  }
  .dropdown-content{
    right: 0.4rem;
  }
 
  }

  @media screen and (max-width: 575px) {
    .blur-background{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.5);
      /* z-index: 999; */
    }
    .img-logo-nav{
      width: 51px;
    }
    .logo {
      margin-left: -6px;
  }
    .three-dot {
      margin-right: 0.2rem;
    }
    .header-right-child-one {
      padding: 2px 4px 2px 4px;
      font-size: 11px;
      height: 1.8rem;
      align-items: center;
    }
    .header-right-child-two{
      padding: 2px 4px 2px 4px;
      font-size: 11px;
      height: 1.8rem;
      align-items: center;
    }
    .three-dot span {
      font-size: 20px;
  }

  .dropdown-content{
    /* width: 100vw;
    right: 0rem;
    margin-top: 30vh;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
   justify-content: space-between !important;
    bottom: 0rem; */
    box-sizing: border-box;
        width: 100vw;
        right: 0rem;
        margin-top: 26vh;
        min-height: 65vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;
        bottom: 0rem;
        max-height: 65rem;
  }
  }

  @media screen and (max-width: 390px) {
    .three-dot {
      margin-right: 0.2rem;
    }
    .header-right-child-one {
      padding: 2px 1px 2px 1px;
      font-size: 10px;
      height: 1.8rem;
      align-items: center;
    }
    .header-right-child-two{
      padding: 2px 1px 2px 1px;
      font-size: 10px;
      height: 1.8rem;
      align-items: center;
    }
    .three-dot span {
      font-size: 20px;
  }
  }
