.main-center-wrap{
    margin-top: 110px;
    /* background: blueviolet; */
  }
  .main-center-container-wrap{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0;
  
  }
  .main-center-container-child-wrap{
    display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      margin-top: 0px;
      width: 100%;
      /* background: rgba(97, 97, 137, 0.675); */
  }
  
  .main-center-container-child-card-wrap{
    padding: 40px;
    gap: 22px;
    width: 648px;
   
  
  display: flex;
  border-radius: 16px;
  /* background: rgb(36, 36, 36); */
  background: #151313;
  padding: 0px 16px 24px;
  flex-direction: column;
  }
  .Wrap-First-input{
    background:#1d1c1c;
    display: flex;
    flex-direction: column;
    width: 4rem;
  }


  .second-main-container-child-one-five-wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 6rem; */
    min-height: 8rem;
    background: #1d1c1c;
    border-radius: 1rem;
    padding: 8px;
  
  
  }

  .second-main-container-child-one-five-first-wrap{
    color:#1d1c1c;
    display: flex;
    justify-content: flex-start;
  }
  .second-main-container-child-one-five-second-wrap{
    display: flex;
    justify-content: space-between;
  }
  .second-main-container-child-one-five-second-input-wrap{
    background: transparent;
    border: none;
    outline: none;
    height: 3rem;
    /* background: #262525; */
    width: 70%;
    font-size: 32px;
    border-right: 1px solid rgb(50, 49, 49);
  }
 
  .second-main-container-child-one-five-second-dropdown-wrap{
    display: flex;
    justify-content: flex-end;
    width: 30%;
    padding-right: 16px;
    align-items: center;
  }
  .second-main-container-child-one-five-second-dropdown-drp-wrap{
    background: black;
    border-radius: 16px;
    color: white;
    padding: 4px 24px 4px 24px;
  }
  .second-main-container-child-one-five-third-wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
 
    gap: 8px;
  }
  .balance{
    font-size: 13px;
  }
  .max-btn{
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 2px;
    cursor: pointer;
  }

  .second-main-container-child-one-six-wrap{
    display: flex;
    justify-content: center;
    margin-right: 7px;
  }
  .wrapUnwrap{
    
    background: #212322;
    height: 3rem;
    color: white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .wrapUnwrapDisable{
    background: #151817;
    height: 3rem;
    color: rgb(136, 66, 66);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    pointer-events: auto! important;
     cursor: not-allowed! important;
  
    
  }

  .transaction{
    display: flex;
    justify-content:center;
    gap: 20px;
    align-items: center;
  }
  @media screen and (max-width: 1250px) {
   
    
  }

  @media screen and (max-width: 768px) {
    .main-center-container-child-card-wrap{
      width: 80%;
}
    
  }

  @media screen and (max-width:575px){
    .main-center-container-child-card-wrap{
      width: 90%;
}
    .second-main-container-child-one-five-second-input-wrap{
      width: 61%;
    }
      .second-main-container-child-one-five-second-dropdown-wrap{
        width: 39%;
      }
  }