.main-center-claim{
    margin-top: 110px;
    /* background: blueviolet; */
    box-sizing: border-box;
  }
  .main-center-container-claim{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0;
  
  }
  .main-center-container-child-claim{
    display: flex;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      margin-top: 0px;
      width: 100%;
      /* background: rgba(97, 97, 137, 0.675); */
  }
  
  .main-center-container-child-card-claim{
    padding: 40px;
    gap: 20px;
    width: 648px;
  
  display: flex;
  border-radius: 24px;
  background: var(--chakra-colors-backgroundCard);
  padding: 0px 16px 24px;
  flex-direction: column;
  /* background: rgb(47, 46, 46); */
  }

  /* for the fisrt div */
  .fisrt-stake-container-claim {
    /* background: #262525; */
  
    /* margin-bottom: 1rem; */
    display: flex;
    gap: 10px;
    /* max-width: 37rem; */
    /* min-height: 13rem; */
 
    border-radius: 9px;
    justify-content: space-between;
    /* padding: 20px; */
  }

  .fisrt-stake-container-claim-one{
    /* background: #414040; */
    background: #151313;
    width: 50%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    
  }
  .fisrt-stake-container-claim-two{
    background: #151313;
    width: 50%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .firstsection{
    height: 70%;
 
    background: #06f7f7;
    color: #050505 !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
  }
  .secondsection{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    margin-top: 8px;
  }
  .img-one{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 1px solid white;
  }
  .sec-container-claim-three{
    display: flex;
  
  }

  .sec-container-claim{
    box-sizing: border-box;
    margin-bottom: 1rem;
    display: flex;
    gap: 10px;
    /* max-width: 37rem; */
    min-height: 13rem;
  
    border-radius: 9px;
    background: #151313;
    flex-direction: column;
    padding: 16px;
  }
  .sec-container-claim-two{
    display: flex;
    justify-content: space-between;
  }
  .sec-container-claim-three{
    display: flex;
    justify-content: space-between;
  }
  .sec-container-claim-four{
    background: #06f7f7;
    border-radius: 16px;
    padding: 3px 5px;
    text-align: center;
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0.8rem;
  }
  .sec-container-claim-five{
    background: #050505;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 600;
  }
  .third-container-claim{
    
    padding: 16px;
    display: flex;
    gap: 10px;
    /* max-width: 37rem; */
    min-height: 13rem;
    margin-bottom: 2rem;
    border-radius: 9px;
    background: #151313;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

  }
  .third-container-claim-one{
    display: flex;
  }
  .third-container-claim-four{
    display: flex;
    justify-content: space-between;
  }

  .blurr{
    position: absolute;
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    /* z-index: 999; */
  }
  /* Blurring effect */
/* Blurring effect */
.blurClaim {
  filter: blur(5px);
}

/* Overlay styles */
.overlayClaim {
  position: fixed;
  /* top: 0;
  left: 0; */
  /* width: 100%; */
  height: 100%;
  background: rgba(22, 22, 22, 0.5); /* Semi-transparent background */
  z-index: 999; /* Below the popup but above other content */
}

/* Popup styles */
.popupClaim {
  position: fixed; /* or absolute if you want it relative to a parent */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(0, 0, 0);
  color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  width: 20rem;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
}

.popupClaim-btn{
  background: rgb(20, 20, 20);
  color: white;
  padding: 10px 20px;
  border-radius: 16px;
  cursor: pointer;
}


  @media screen and (max-width: 1250px) {
 
  }

  @media screen and (max-width: 730px) {
    .main-center-container-child-card-claim{
        width: 100%;
    }
  }
  @media screen and (max-width: 575px) {
    .fisrt-stake-container-claim {
        flex-direction: column;
    }
        .fisrt-stake-container-claim-one{
            width: 100%;
        }
        .fisrt-stake-container-claim-two{
            width: 100%;
        }
        .firstsection{
            height: 75%;
        }
  }