body{
  background: black;
  font-family: Azeret Mono, monospace;
  box-sizing: border-box;
}

.home {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  overflow-wrap: break-word;
  padding-top: 2rem;
  background: black;
  width: 100%;
  height: 100vh;
}
*{
  color: white;
  box-sizing: border-box;
}

