.logo{
    font-size: 28px;
    font-weight: 800;
  }
  .img-logo-side{
    width: 120px;
    height: 60px;
  }
  .sidebar {
    padding: 0px 28px 0px 40px;
    display: block;
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    min-height: 100vh;
    /* background: rgb(6 5 5); */
    color: white;
    box-sizing: border-box;
    margin-top: -17px;
  }
  .sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 172px;
    margin-top: 64px;
  }
  .sidebar-menu a {
    cursor: pointer;
    text-decoration: none;
    outline: transparent solid 2px;
    outline-offset: 2px;
    color: inherit;
  }
  .sidebar-menu-heading {
    padding-left: 12px;
  }
  
  .sidebar-menu-option {
    display: flex;
    padding: 12px 16px 12px 12px;
    border-radius: 4px;
    gap: 12px;
    background: rgb(14 13 13);
    box-shadow: 2px 2px 2px -1px #06f7f7;
    align-items: flex-start;
    cursor: pointer;
    font-size: 16px;
  }
  
  .acive-color{
    box-shadow:2px 1px 7px 2px #06f7f7;
    transition: all 300ms linear;
  }
  .sidebar-menu-option-name {
    font-weight: 500;
    line-height: 24px;
  }
  
  .top-side{
    display: flex;
    justify-content: space-between;
  }
  .close{
    display: none;
  }

  
  /* ======================sidebar end */

  @media screen and (max-width: 1250px) {
    .sidebar{
      display: none;
      left: -100%; /* for the animation make that forcefully to push left side*/
      animation:showMenu 400ms ease forwards;
      z-index: 3;
      background: black;
      /* width: 40%; */
      width: 17rem;
    }

    .sidebar-open{
      display: block;
      left: -100%; /* for the animation make that forcefully to push left side*/
      animation:showMenu 400ms ease forwards;
      z-index: 3;
      background: black;
      width: 40%;
    }


    @keyframes showMenu {
      to{
       left: 0;
      }
    }

    .sidebar-Show{
      display: block;
    }
    .close{
      display:block;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 575px) {
    .sidebar-open{
      display: block;
      left: -100%; /* for the animation make that forcefully to push left side*/
      animation:showMenu 400ms ease forwards;
      z-index: 10000;
      background: black;
      width: 60%;
    }


    @keyframes showMenu {
      to{
       left: 0;
      }
    }
  }